/* Navbar.css */


/* Global link styles */

a {
    color: #fff;
    /* Default link color */
    text-decoration: none;
    /* Remove underline */
}


/* Hovered link */

a:hover {
    color: #dd3333;
    /* Color on hover */
}


/* Active link */

a:active,
.last-clicked {
    color: #ff6666;
    /* Color when clicked or last clicked */
}


/* Dropdown Menu Styles */

.dropdown-content {
    display: none;
    /* Hide the dropdown content by default */
    position: absolute;
    background-color: #000;
    /* Black background color */
    min-width: 160px;
    border-radius: 5px;
    padding: 10px 0;
    z-index: 1;
}

.dropdown-content.show {
    display: block;
    /* Show the dropdown content when it's open */
}

.dropdown-content li {
    margin: 5px 0;
}

.dropdown-content li a {
    color: #fff;
    /* White text color */
    padding: 10px 20px;
    text-decoration: none;
    display: block;
}

.dropdown-content li a:hover {
    background-color: #dd3333;
    /* Red color on hover */
}


/* Hamburger menu icon */

.menu-icon {
    display: none;
    background-color: black;
    /* Hide the hamburger menu by default */
    font-size: 24px;
    cursor: pointer;
    color: #ff0000;
    /* White color */
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 2;
    /* Ensure it's above other content */
}


/* Responsive styling for mobile view */

@media screen and (max-width: 768px) {
    .menu-icon {
        display: flex;
        /* Show the hamburger menu on mobile devices */
    }
    .nav-links {
        display: none;
        /* Hide the navigation links by default on mobile */
        flex-direction: column;
        /* Display navigation links vertically */
        padding: 10px 0;
        /* Add padding */
        margin-top: 20px;
        /* Add margin to separate from menu icon */
        border-radius: 10px;
        /* Add border radius */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        /* Add box shadow */
        background-color: #000;
        /* Black background color */
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        z-index: 1;
    }
    .nav-link-item {
        margin: 10px;
    }
    .nav-links.show {
        display: flex;
        /* Show the navigation links when the menu is open */
        position: fixed;
        bottom: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    /* Show dropdown content when clicking on Transportation */
    .dropdown.clicked .dropdown-content {
        display: block;
    }
    /* Toggle the menu open and closed */
    .menu-icon.clicked+.nav-links {
        display: flex;
    }
}


/* Hide the hamburger menu icon on larger screens (computers) */

@media screen and (min-width: 769px) {
    .menu-icon {
        display: none;
    }
    .nav-links {
        display: flex;
        background-color: #000;
        /* Show the navigation links on desktop */
        justify-content: center;
        /* Center the links horizontally */
    }
    .nav-link-item {
        margin: 0 20px;
        /* Add margin between links */
    }
    /* Show dropdown content on hover for desktop */
    .dropdown:hover .dropdown-content {
        display: block;
    }
}