/* Reset default browser styles (optional) */

body,
h1,
h2,
p,
ul {
    margin: 0;
    padding: 0;
}


/* Page container styles */

.page-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(31, 31, 31, 0.869);
}


/* Header styles */

h1 {
    font-size: 28px;
    margin-bottom: 20px;
    color: white;
}


/* Paragraph styles */

p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: white;
}


/* List styles */

ul {
    list-style-type: disc;
    list-style: none;
    margin-left: 0;
    margin-bottom: 20px;
    text-align: center;
}

li {
    font-size: 16px;
    line-height: 1.5;
}


/* Strong (bold) text styles */

strong {
    font-weight: bold;
}


/* Slideshow container styles */

.slideshow-container {
    position: relative;
    max-width: 100%;
    margin-bottom: 20px;
}


/* Slides (individual images) */

.slide {
    display: none;
    max-width: 100%;
}

.slide img {
    width: 100%;
    height: auto;
}


/* Navigation arrows */

.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: #fff;
    font-weight: bold;
    font-size: 24px;
    -webkit-user-select: none;
    user-select: none;
    transition: 0.3s ease;
}

.next {
    right: 0;
}


/* Arrow hover styles */

.prev:hover,
.next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}


/* Active slide styles */

.active {
    display: block;
}


/* Center h2 elements */

h2 {
    text-align: center;
}

body {
    background-image: url('/public/images/main/plain_with_cars.jpg');
    background-size: cover;
    background-position: center;
    color: white;
}