.header-container {
    /* background-color: #333; */
    height: auto;
    /* Allow the height to adjust based on content */
    max-height: auto;
    /* Limit the maximum height */
    overflow: hidden;
}

.header-image {
    width: 80%;
    height: auto;
    /* Allow the image to adjust its height while maintaining its aspect ratio */
    display: block;
    /* Remove any extra spacing caused by inline elements */
    margin: 0 auto;
    /* Center the image horizontally within the container */
    object-fit: cover;
    /* Maintain the image's aspect ratio while covering the container */
}

ul {
    list-style: none;
}