/* Slideshow.css */


/* Style for the slideshow container */

.slideshow-container {
    position: relative;
    margin: auto;
}


/* Style for the previous button */

.prev {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
}


/* Style for the next button */

.next {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
}


/* Style for the slides */

.slides {
    display: flex;
    overflow: hidden;
}

.slide {
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s;
}

.slide.active {
    opacity: 1;
}