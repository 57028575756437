footer {
    /* background-color: #333; */
    /* background-color: white
         */
    color: #fff;
    text-align: center;
    /* Center text horizontally */
    padding: 20px 0;
    /* Add some vertical spacing */
    /* Center content vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer-content {
    background-color: black;
    color: white;
    max-width: 800px;
    padding: 20px;
}

.footer-content p {
    font-size: 16px;
    margin: 0;
}


/* Default link color */

a.wd {
    color: black;
    text-decoration: none;
}


/* Change link color on hover */

a.wd:hover {
    color: gray;
}

a.wd:visited {
    color: white;
}

ul {
    list-style: none;
}