.ContactForm-container {
    max-width: 600px;
    /* Set your desired maximum width */
    margin-top: 20px;
    /* Add some spacing from the footer content */
}


/* Style form elements */

.ContactForm input[type="text"],
.ContactForm input[type="email"],
.ContactForm textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.ContactForm textarea {
    height: 150px;
    /* Adjust the height as needed */
}

.ContactForm button {
    background-color: #007bff;
    /* Set your desired button background color */
    color: #fff;
    /* Set your desired button text color */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.ContactForm button:hover {
    background-color: #0056b3;
    /* Set the hover color */
}

ul {
    list-style: none;
}