/* Default width for larger screens */
.fb-page-container {
    width: 500px;
    margin: 0 auto; /* Center horizontally */
  }
  
  /* Adjust the width for smaller screens */
  @media screen and (max-width: 667px) {
    .fb-page-container {
      width: 80%; /* Adjust this width as needed for optimal display on mobile */
      max-width: 500px; /* Set a maximum width if required */
    }
  }
  